import Api from "../../common/helpers/api";
import getApiUrl from "../../common/helpers/api/urls";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const chatInitMsg = {
    msgType: 'aiChat',
    message: {
      user: 'hi',
      assistant: `Hi! I'm Shelly. Let's start planning your vacay!`,
      disclaimer: '',
      isCookieCenterEnabled:false,
      packages: [],
      chatSuggestions: [
      ],
      suggestionComponent: {
        componentType: '',
        suggestionPrompt: '',
        counterPrompt: '',
        suggestionCd: '',
        numberOfSelection: 0,
        preferedActivityList: []
      },
      chatPreferences: [],
      selectedOrg: 'DFW'
    },
    isCtrlActive: false,
    ctrlData: {},
    selectedValues: ''
  };
const initialState = {
    loading: false,
    resStatus: false,
    resStatusCode: 200,
    responseData: [],
    message: "",
    chatSuggestions: [],
    aiAsstMessage: [],
    aiAsstQuestionsList: {
        userType: 'aiChatQuestions',
        message: {
            systemMessage: "",
            destinations: [],
            lengthofstay: [],
            origins: [],
            pricerange: { min: 0, max: 0 },
            questions: [],
            themes: []
        }
    },
    userSelectedQuestions: {
        price_range: [0, 0],
        travel_dates: [null, null],
        chat_bot_message: "",
        prefered_activity_list: {},
        all_prefered_activity_list: {},
        top_destinations: {},
        top_resorts: {},
        amenities: {},
        activities: {}
    }
};

export const getAiShoppingInfoSlice = createAsyncThunk(
    'aiShoppingInfo/getAiShoppingInfo',
    async (data) => {
        const { urlKeyName, formData = {} } = data;
        const url = getApiUrl.getApiUrlInfo(urlKeyName);
        return await Api.get(url, formData);
    }
);

const aiShoppingInfoSlice = createSlice({
    name: 'aiShoppingInfo',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAiShoppingInfoSlice.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAiShoppingInfoSlice.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload.status) {
                    state.resStatus = true;
                    state.resStatusCode = action.payload.status;
                    state.responseData = action.payload.data;
                    state.chatSuggestions = action.payload.data.chatSuggestions;
                    state.userSelectedQuestions.price_range = [
                        Number(action.payload.data.pricerange.min),
                        Number(action.payload.data.pricerange.max)
                    ];
                    state.userSelectedQuestions.travel_dates = [null, null];
                } else {
                    state.resStatus = true;
                    state.resStatusCode = action.payload.statusCode;
                    state.responseData = action.payload.data;
                    state.message = action.payload.message;
                }
            })
            .addCase(getAiShoppingInfoSlice.rejected, (state) => {
                state.loading = false;
                state.message = "Something went wrong!";
            });
    },
    reducers: {
        clearUserCtrlData: (state, action) => {
            if (action.payload.key_type === "session_clear") {
                state[action.payload.key_name] = action.payload.key_value;
            } else {
                state.userSelectedQuestions[action.payload.key_name] = action.payload.key_value;
            }
        },
        userChatSuggestionsEvent: (state, action) => {
            state.chatSuggestions = action.payload;
            state.loading = false;
        },
        userSelectedQuestionEvent: (state, action) => {
            state.userSelectedQuestions[action.payload.key_name] = action.payload.key_value;
        },
        userSelectedQuestionListEvent: (state, action) => {
            const { numberOfSelection, key_value } = action.payload;
            if (numberOfSelection !== 0) {
                if (numberOfSelection === 1) {
                    Object.keys(state.userSelectedQuestions.prefered_activity_list).forEach(key => {
                        delete state.userSelectedQuestions.all_prefered_activity_list[key];
                        delete state.userSelectedQuestions.prefered_activity_list[key];
                    });
                    state.userSelectedQuestions.all_prefered_activity_list[key_value] = true;
                    state.userSelectedQuestions.prefered_activity_list[key_value] = true;
                }
                return;
            }

            if (state.userSelectedQuestions.all_prefered_activity_list[key_value] === undefined) {
                state.userSelectedQuestions.all_prefered_activity_list[key_value] = true;
                state.userSelectedQuestions.prefered_activity_list[key_value] = true;
            } else {
                delete state.userSelectedQuestions.all_prefered_activity_list[key_value];
                delete state.userSelectedQuestions.prefered_activity_list[key_value];
            }
        },
        aiChatQusetions: (state, action) => {
            state.aiAsstQuestionsList = action.payload;
        },
        aiChatWelcomeMsg: (state) => {
            state.loading = false;
            state.aiAsstMessage.push(chatInitMsg)
        },
        aiChat: (state, action) => {
            state.aiAsstMessage.push(action.payload);
            state.loading = false;
        },
        userChat: (state, action) => {
            state.aiAsstMessage.push(action.payload);
            state.loading = action.payload.isLoading !== undefined ? action.payload.isLoading : true;
        },
        updateLoadingStatus: (state, action) => {
            state.loading = action.payload.status !== undefined ? action.payload.status : false;
        },
        chatListUpdation: (state, action) => {
            state.aiAsstMessage = action.payload;
        },
        clearUserSelectedPreferencesQuestionStateValue: (state, action) => {
            state.userSelectedQuestions[action.payload.key_name] = action.payload.key_value;
        }
    }
});

export const {
    aiChatQusetions,
    aiChatWelcomeMsg,
    aiChat,
    clearUserSelectedPreferencesQuestionStateValue,
    chatListUpdation,
    userChat,
    updateLoadingStatus,
    userSelectedQuestionEvent,
    userChatSuggestionsEvent,
    userSelectedQuestionListEvent,
    clearUserCtrlData
} = aiShoppingInfoSlice.actions;

export default aiShoppingInfoSlice.reducer;
